<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Batch Update Items</h3>

	<p>When many items need to be modified in the same way, the Batch Update Items Tool is the fastest way.
	<p>System Admins, System Reviewers, Framework Editors/Reviewers can batch update items.</p>
	<p>To use the <strong>Batch Update Item Tool:</strong>
		<img srcset="/docimages/edit_menu.png 3x" class="k-help-img float-right">
			<ol class="k-no-clear">
				<li><span v-html="link('signing_in', 'Sign in')"></span> to  <span v-html="app_name"/> with Framework / System Admin or Editor permissions.</li>
				<li>Select a framework you want to edit to create an association.</li>
				<li><nobr><v-icon small>fas fa-edit</v-icon></nobr> Enter <strong>edit mode</strong> by clicking the EDIT icon from the toolbar.</li>
				<li>Before making batch updates, we recommend creating a <span v-html="link('archives', 'saved stamped archive')"></span> to easily access and restore the current version.</li>
				<li>Select the item you want to edit in the framework tree (domain, strand, cluster, or standard)</li>
				<li>Once you’ve selected an <strong>item card</strong> will appear. <nobr><v-icon small>fas fa-edit</v-icon></nobr> Select the <strong>EDIT BUTTON.</strong></li>
				<li>From the <strong>edit menu</strong>, select <strong>BATCH UPDATE ITEMS</strong> to open the <strong>Batch Editor</strong> tool and window. </li>
				<li>Select one, or multiple, parent items to identify which tree nodes the batch editor will perform its search to edit items.</li>
				<li>Click <strong>ALTER PROPERTY…</strong> and select which CASE property you would like to modify: Full Statement, Human-Readable Code, Notes, etc.</li>
				<li>The <strong>Search for: (optional)</strong> input determines what the editing tool will search for to identify the item(s) and the part(s) of the item(s) to change</li>
				<li>The <strong>Replace with:</strong> input determines what the editing tool will replace the searched for terms.</li>
				<li>Regular Expressions can be used in both the Search and Replace inputs by wrapping the regular expression in forward slashes, “/”.</li>
				<li>Determine if you would like to apply changes to the selected parent items and their children, grandchildren, etc., only the parent items selected, or only the children, grandchildren, etc. of the selected parent item.</li>
				<img srcset="/docimages/apply_changes.png 1.5x" class="k-help-img float-right">
				<li>Click <strong>SIMULATE BATCH UPDATE</strong> and <span v-html="app_name"/> will process the search and replace. If the search and replace results in any potential changes, a Batch Alter Items window will appear displaying what will be altered.
					<ol type="a">
						<li>If no items will be altered, a window will appear stating that none of the items selected would be updated using the criteria set.</li>
					</ol>
				</li>
				<li>To accept these changes, click <strong>MAKE THESE BATCH UPDATES!</strong> Click “ok” and then refresh the page to view the changes.</li>
			</ol>	
	</p>
	<p><strong>NOTE:</strong> For help with the <strong>batch updating tool</strong>, <a href="mailto:support@commongoodlt.com">support@commongoodlt.com</a></p>



	</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>